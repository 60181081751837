var JPlaceHolder = {
    _check : function(){
        return 'placeholder' in document.createElement('input');
    },
    init : function(){
        if(!this._check()){
            this.fix();
        }
    },
    fix : function(){
        jQuery(':input[placeholder]').each(function(index, element) {
        	//console.log("fix : function()");
            var self = $(this), txt = self.attr('placeholder');
            var id = self.attr('id');
            self.wrap($('<div></div>').css({position:'relative', zoom:'1', border:'none', background:'none', padding:'none', margin:'none'}));
            var pos = self.position(), h = self.outerHeight(true), w = self.outerWidth(true), paddingleft = self.css('padding-left'), fontSize = self.css('font-size');
            var span = '<span id="' + id + '-span"></span>';
            var holder = $(span).text(txt).css({position:'absolute', left:pos.left, top:pos.top, height:h, width:w, lineHeight:h+"px",'font-size':fontSize, paddingLeft:paddingleft, color:'#aaa'}).appendTo(self.parent());
            
            self.focusin(function(e) {
            	if(self.val()){
            		holder.hide(); 	
            	}
            	
            }).focusout(function(e) {
                if(!self.val()){
                    holder.show();
                }
            });
            
            self.keyup(function(e){
            	if(self.val()){
            		holder.hide(); 	
            	}
            });
            holder.click(function(e) {
                if(!self.val()){
                    holder.show();
                } else {
                	holder.hide(); 	
                }
                self.focus();
            });
        });
    }
};

$(function(){
    JPlaceHolder.init();    
});